import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { logout } from "../actions/userActions";
import "../Styles/Index.css";

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  if (window.location.pathname === "/" && !window.location.origin.includes("localhost") && !window.location.origin.includes("127.0.0.1")) {
    return null;
  }


  return (
    <header>
      <Navbar expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <span className="p-2">
                <img src="images/cam-logo.png" alt="img" className="img-logo"></img>
              </span>
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">


              {userInfo ? (
                <>
                  {userInfo.isAdmin ? (
                    <>
                      <LinkContainer to="/admin/userslist">
                        <Nav.Link>Users</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/admin/productlist">
                        <Nav.Link>Products</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/admin/orderlist">
                        <Nav.Link>Orders</Nav.Link>
                      </LinkContainer>
                    </>
                  ) :
                    <><LinkContainer to="/products">
                      <Nav.Link>
                        Products
                      </Nav.Link>
                    </LinkContainer>
                    </>
                  }
                  <NavDropdown title={userInfo.name} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Log Out
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    {/* <i className="fas fa-user"></i>  */}
                    Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {!userInfo?.isAdmin && <LinkContainer to="/cart">
                <Nav.Link>
                  <i className="fas fa-shopping-cart"></i>
                </Nav.Link>
              </LinkContainer>}

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header >
  );
};

export default Header;
